<template>
  <div>
    <!-- 临时医嘱 -->
    <div class="hande">
      <div style="display: flex; justify-content: space-between">
        <div></div>
        <div style="display: flex; justify-content: space-between">
          <div class="rights" @click="online">
            <div class="icons">
              <el-icon><plus /></el-icon>
            </div>
            <div style="color: #fff">联机数据</div>
          </div>
          <div class="rights" @click="add">
            <div class="icons">
              <el-icon><plus /></el-icon>
            </div>
            <div style="color: #fff">新增</div>
          </div>
        </div>
      </div>

      <!-- 表格部分 -->
      <div style="margin-top: 20px">
        <el-table
          :data="tableData"
          size="small"
          stripe
          height="350"
          style="width: min-100%"
        >
          <el-table-column prop="record_time" label="记录时间" />
          <el-table-column prop="diandao" label="呼吸（次/min）" />
          <el-table-column prop="dxueliuliang" label="血流量（ml/min）" />
          <el-table-column prop="jingmaiya" label="静脉压（mmHg）" />
          <el-table-column prop="chaolvlv" label="超滤率（ml/h）" />
          <el-table-column prop="chaolvliang" label="超滤量（ml）" />
          <el-table-column prop="xinlv" label="心率" />
          <el-table-column prop="shousuoya" label="收缩压mmHg" />
          <el-table-column prop="shuzhangya" label="舒张压mmHg" />
          <el-table-column prop="chanci" label="穿刺(置管)渗血、血肿" />
          <el-table-column prop="tiwen" label="体温°C" />
          <el-table-column prop="zhengzhuang" label="症状" />
          <el-table-column prop="chuli" label="处理" />
          <el-table-column prop="nurse_id" label="护士签名" />
          <el-table-column fixed="right" label="操作" width="120">
            <template #default="scope">
              <div class="Dsefault">
                <div
                  class="Colum"
                  @click="deleteRow(scope.row)"
                  style="background: #3166ae"
                >
                  改
                </div>
                <el-popconfirm
                  title="是否确认删除?"
                  @confirm="detecdele(scope.row.id)"
                >
                  <template #reference>
                    <span
                      class="Colum"
                      style="background: rgba(255, 62, 62, 0.1); color: #ff3e3e"
                    >
                      删
                    </span>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页 -->
      <div class="demo-pagination-block">
        <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :small="small"
          :disabled="disabled"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 联机数据弹窗 -->
    <BaseDialog
      :title="'联机数据【当前床位：】'"
      :isshow="bodyvlus"
      @handleShow="handleShoow"
      width="60%"
      height="auto"
    >
      <div class="Diahand">
        <!-- 头部左侧 -->
        <div style="display: flex; width: 60%">
          <div class="txttime">起始时间：</div>
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="datetime"
              placeholder="Select date and time"
            >
            </el-date-picker>
          </div>

          <div class="txttime">结束时间：</div>
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="datetime"
              placeholder="Select date and time"
            >
            </el-date-picker>
          </div>
        </div>

        <!-- 头部右侧 -->
        <div class="butts">
          <div class="iiocns">
            <el-icon><search /></el-icon>
          </div>
          查询
        </div>
      </div>

      <!-- 表格 -->

      <div style="margin-top: 20px">
        <el-table
          :data="tableData"
          size="small"
          stripe
          height="350"
          style="width: min-100%"
        >
          <el-table-column fixed type="selection" width="55" />
          <el-table-column prop="date" label="时间" />
          <el-table-column prop="diandao" label="呼吸" />
          <el-table-column prop="address" label="静脉压" />
          <el-table-column prop="zip" label="动脉压" />
          <el-table-column prop="zip" label="收缩压" />
          <el-table-column prop="zip" label="舒张压" />
          <el-table-column prop="zip" label="心率" />
          <el-table-column prop="zip" label="体温" />
          <el-table-column prop="zip" label="呼吸" />
          <el-table-column prop="zip" label="跨膜压" />
          <el-table-column prop="zip" label="血流速" />
          <el-table-column prop="zip" label="有效血流速" />
          <el-table-column prop="zip" label="透析液流速" />
          <el-table-column prop="zip" label="透析液温度" />
          <el-table-column prop="zip" label="超滤量" />
          <el-table-column prop="zip" label="超滤率" />
          <el-table-column prop="zip" label="置换液流速" />
          <el-table-column prop="zip" label="置换液量" />
          <el-table-column prop="zip" label="肝素量" />
          <el-table-column prop="zip" label="kt/v" />
          <el-table-column prop="zip" label="剩余时间(min)" />
        </el-table>
      </div>

      <!--分页 -->
      <div class="demo-pagination-block">
        <el-pagination
          v-model:currentPage="currentPage4"
          v-model:page-size="pageSize4"
          :page-sizes="[100, 200, 300, 400]"
          :small="small"
          :disabled="disabled"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>

      <!-- 下划线 -->
      <el-divider></el-divider>

      <!-- 底部按钮区 -->
      <div class="Qinsnl">
        <div style="width: 60%"></div>
        <div class="Qinsnl">
          <div class="ider">取消</div>
          <div class="vider">确定</div>
        </div>
      </div>
    </BaseDialog>

    <!-- 新增数据弹窗 -->
    <BaseDialog
      :title="'新增检测记录'"
      :isshow="vilsv"
      @handleShow="handleShow"
      width="62%"
      height="auto"
    >
      <div class="Diahand">
        <!-- 头部左侧 -->
        <div></div>

        <!-- 头部右侧 -->
        <div class="butts">
          <div class="seions">
            <el-icon><search /></el-icon>
          </div>
          获取联机数据
        </div>
      </div>

      <!-- 内容区 -->

      <!-- 呼吸： -->
      <div class="bodyinpt">
        <div class="inptext">
          <div class="texsi">呼吸：</div>
          <div class="inpt">
            <el-input v-model="formInline.diandao" placeholder="请填写" />
          </div>
        </div>

        <!-- 血流量 -->
        <div class="inptext">
          <div class="texsi">血流量：</div>
          <div class="inpt">
            <el-input v-model="formInline.dxueliuliang" placeholder="请填写">
              <template #append>ml/min</template>
            </el-input>
          </div>
        </div>

        <!-- 静脉压： -->
        <div class="inptext">
          <div class="texsi">静脉压：</div>
          <div class="inpt">
            <el-input v-model="formInline.jingmaiya" placeholder="请填写">
              <template #append>mmHg</template>
            </el-input>
          </div>
        </div>

        <!-- 静脉压： -->
        <div class="inptext">
          <div class="texsi">超滤率：</div>
          <div class="inpt">
            <el-input v-model="formInline.chaolvlv" placeholder="请填写">
              <template #append>ml/h</template>
            </el-input>
          </div>
        </div>

        <!-- 静脉压： -->
        <div class="inptext">
          <div class="texsi">超滤量：</div>
          <div class="inpt">
            <el-input v-model="formInline.chaolvliang" placeholder="请填写">
              <template #append>ml</template>
            </el-input>
          </div>
        </div>

        <!-- 心率： -->
        <div class="inptext">
          <div class="texsi">心率：</div>
          <div class="inpt">
            <el-input v-model="formInline.xinlv" placeholder="请填写" />
          </div>
        </div>

        <!-- 收压缩： -->
        <div class="inptext">
          <div class="texsi">收压缩：</div>
          <div class="inpt">
            <el-input v-model="formInline.shousuoya" placeholder="请填写">
              <template #append>mmHg/h</template>
            </el-input>
          </div>
        </div>

        <!-- 舒张压： -->
        <div class="inptext">
          <div class="texsi">舒张压：</div>
          <div class="inpt">
            <el-input v-model="formInline.shuzhangya" placeholder="请填写">
              <template #append>mmHg</template>
            </el-input>
          </div>
        </div>

        <!-- 穿刺(置管)渗血： -->
        <div class="inptext">
          <div class="texsi" style="width: 30%">穿刺(置管)渗血：</div>
          <div class="inpt">
            <el-input v-model="formInline.chanci" placeholder="请填写" />
          </div>
        </div>

        <!-- 舒张压： -->
        <div class="inptext">
          <div class="texsi">体温：</div>
          <div class="inpt">
            <el-input v-model="formInline.tiwen" placeholder="请填写">
              <template #append>mmHg</template>
            </el-input>
          </div>
        </div>
        <!-- 穿刺(置管)血肿： -->
        <div class="inptext">
          <div class="texsi" style="width: 30%">穿刺(置管)血肿：</div>
          <div class="inpt">
            <el-input v-model="formInline.chuanci_blood" placeholder="请填写">
            </el-input>
          </div>
        </div>

        <!-- 症状： -->
        <div class="inptext" style="width: 100%">
          <div class="texsi" style="width: 8.1%">症状：</div>
          <div class="inpt" style="width: 85%">
            <el-input
              v-model="formInline.zhengzhuang"
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              placeholder="请填写"
            >
            </el-input>
          </div>
          <div class="pluslis" @click="icu(1)">
            <el-icon><plus /></el-icon>
          </div>
        </div>

        <!-- 处理： -->
        <div class="inptext" style="width: 100%">
          <div class="texsi" style="width: 8.1%">处理：</div>
          <div class="inpt" style="width: 85%">
            <el-input
              v-model="formInline.chuli"
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              placeholder="请填写"
            >
            </el-input>
          </div>
          <div class="pluslis" @click="icu(2)">
            <el-icon><plus /></el-icon>
          </div>
        </div>

        <!-- 记录时间： -->
        <div style="display: flex; width: 100%; margin-top: 10px">
          <div class="inptext">
            <div class="texsi">记录时间：</div>
            <div class="inpt">
              <el-date-picker
                v-model="formInline.record_time"
                type="datetime"
                placeholder="Select date and time"
              >
              </el-date-picker>
            </div>
          </div>
          <!-- 护士签名： -->
          <div class="inptext">
            <div class="texsi" style="width: 30%">护士签名：</div>
            <div class="inpt">
              <el-select
                v-model="formInline.nurse_id"
                class="m-2"
                placeholder="Select"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>

      <!-- 下划线 -->
      <el-divider></el-divider>

      <!-- 底部按钮区 -->
      <div class="Qinsnl">
        <div style="width: 60%"></div>
        <div class="Qinsnl">
          <div class="ider" @click="detectionQX">取消</div>
          <div class="vider" @click="detectionQR">确定</div>
        </div>
      </div>

      <!-- 修改并发症弹窗 -->
      <BaseDialog
        :title="icuIndex === 1 ? '新增症状' : '新增处理'"
        :isshow="icuval"
        @handleShow="handleSh"
        width="40%"
        height="auto"
      >
        <!-- 内容区 -->
        <div class="checlis">
          <div v-for="(item, index) in tableData" :key="index">
            <el-checkbox v-model="checked1" size="large">
              {{ item.name }}</el-checkbox
            >
          </div>
        </div>
        <!-- 下划线 -->
        <el-divider></el-divider>

        <!-- 底部按钮区 -->
        <div class="Qinsnl">
          <div style="width: 60%"></div>
          <div class="Qinsnl">
            <div class="ider">取消</div>
            <div class="vider">确定</div>
          </div>
        </div>
      </BaseDialog>
    </BaseDialog>
  </div>
</template>

<script>
import { Plus, Search } from '@element-plus/icons-vue'
import BaseDialog from '@/components/Dialog/index.vue'
import service from '@/utils/request'
import { reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  props: {
    create: {
      type: Object,
      required: true,
    },
  },
  components: {
    Plus,
    BaseDialog,
    Search,
  },
  setup(proos) {
    const satar = reactive({
      bodyvlus: false,
      vilsv: false,
      icuIndex: 0,
      icuval: false,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      formInline: {},
      total: null,
    })
    const online = () => {
      satar.bodyvlus = true
    }
    const add = () => {
      satar.vilsv = true
    }
    const handleShow = (val) => {
      satar.vilsv = val
    }
    const handleShoow = (val) => {
      satar.bodyvlus = val
    }
    const handleSh = (val) => {
      satar.icuval = val
    }
    const icu = (val) => {
      console.log(1231232312321)
      satar.icuIndex = val
      satar.icuval = true
    }
    // 获取监测列表
    const monitor = async () => {
      const data = {
        record_id: proos.create.id,
        patient_id: proos.create.patient_id,
        current: satar.currentPage,
        size: satar.pageSize,
      }
      let res = await service.post('api/estimate/monitor', data)
      satar.tableData = res.data.records
      satar.total = res.data.total
      console.log(satar.tableData)
    }
    // 当页数发生改变时
    const handleCurrentChange = (e) => {
      satar.currentPage = e
      monitor()
    }
    // 当条数发生改变时
    const handleSizeChange = (e) => {
      satar.pageSize = e
      monitor()
    }
    // 当患者列表为空的时候
    const open4 = () => {
      ElMessage.error('暂无数据！')
    }
    // 当数据请求成功的时候
    const open3 = () => {
      ElMessage.success('操作成功！')
    }
    // 新增弹窗取消
    const detectionQX = async () => {
      satar.formInline = {}
      satar.vilsv = false
    }
    // 新增弹窗确认
    const detectionQR = async () => {
      if (!satar.formInline.id) {
        satar.formInline.record_id = proos.create.id
        satar.formInline.patient_id = proos.create.patient_id
        let res = await service.post(
          'api/estimate/monitorAdd',
          satar.formInline
        )
        console.log(res)
        if (res.code === 0) {
          open3()
          satar.formInline = {}
          satar.vilsv = false
          monitor()
        }
      } else {
        let res = await service.post(
          'api/estimate/monitorEdit',
          satar.formInline
        )
        console.log(res)
        if (res.code === 0) {
          open3()
          satar.formInline = {}
          satar.vilsv = false
          monitor()
        }
      }
    }
    // 删除记录
    const detecdele = async (id) => {
      let res = await service.post('api/estimate/deleteMonitor', { id: id })
      if (res.code === 0) {
        open3()
        monitor()
      }
    }
    // 修改
    const deleteRow = (row) => {
      satar.formInline = row
      satar.vilsv = true
      satar.formInline.record_id = proos.create.id
      satar.formInline.patient_id = proos.create.patient_id
      console.log(satar.formInline)
    }
    return {
      ...toRefs(satar),
      online,
      add,
      handleShow,
      handleShoow,
      icu,
      handleSh,
      monitor,
      handleCurrentChange,
      handleSizeChange,
      detectionQX,
      open4,
      detectionQR,
      open3,
      deleteRow,
      detecdele,
    }
  },
  created() {
    this.monitor()
  },
}
</script>

<style scoped>
.hande {
  padding: 10px;
  height: 557px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(49, 102, 174, 1),
      rgba(49, 102, 174, 0.3),
      rgba(49, 102, 174, 0),
      rgba(49, 102, 174, 0)
    )
    1 1;
}
.rights {
  display: flex;
  justify-content: space-around;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  padding-top: 6px;
  cursor: pointer;
  margin: 0 20px;
  padding: 6px 20px;
}
.icons {
  width: 19px;
  height: 19px;
  background: #ffffff;
  opacity: 1;
  color: rgba(49, 102, 174, 1);
  border-radius: 15px;
  text-align: center;
  padding-top: 1px;
  font-size: 14px;
  margin-right: 10px;
}
.Dsefault {
  display: flex;
  justify-content: space-around;
}
.Colum {
  width: 21px;
  height: 21px;
  background: #f4a939;
  opacity: 1;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 21px;
}
.Dsefault span:hover {
  background: #ff3e3e !important;
  color: #fff !important;
}
.demo-pagination-block {
  margin-top: 10px;
  margin-left: 370px;
}
.Diahand {
  height: 50px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  line-height: 50px;
}
.txttime {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  margin-right: 10px;
}
.block {
  margin-right: 20px;
}
.butts {
  display: flex;
  justify-content: space-around;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  cursor: pointer;
  color: #fff;
  line-height: 32px;
  padding: 0 20px;
}
.iiocns {
  width: 18px;
  height: 18px;
  background: #fff;
  opacity: 1;
  color: rgba(49, 102, 174, 1);
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 18px;
  margin-top: 7px;
  margin-right: 10px;
}
.seions {
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 18px;
  margin-top: 7px;
  margin-right: 10px;
}
.Qinsnl {
  display: flex;
  justify-content: space-between;
}
.ider {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #3166ae;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  margin: 0 20px;
}
.vider {
  width: 80px;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
.texsi {
  text-align: right;
  width: 25%;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-right: 10px;
}
.inptext {
  width: 33%;
  display: flex;
  margin: 15px 0;
  line-height: 32px;
  height: 32px;
}
.inpt {
  width: 65%;
}
.bodyinpt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pluslis {
  width: 18px;
  height: 18px;
  background: rgba(49, 102, 174, 0.3);
  opacity: 1;
  background: #c4c4c4;
  text-align: center;
  line-height: 10px;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 20px;
  padding-top: 2px;
  cursor: pointer;
}
.checlis {
  height: 300px;
  overflow-y: scroll;
}
</style>
